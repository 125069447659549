import { DELETE_PERMISSION, GROWERS_ATTRIBUTES } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c("a-modal", {
          attrs: {
            "after-close": _vm.afterModalClose,
            "title": "Edit Attribute",
            "width": 520
          },
          scopedSlots: _vm._u([{
            key: "footer",
            fn: function() {
              return [_c("a-button", {
                on: {
                  "click": _vm.cancel
                }
              }, [_vm._v("Cancel")]), _vm.hasDeletePermission && _vm.canDeactivate ? _c("a-button", {
                attrs: {
                  "ghost": "",
                  "type": _vm.toggleBtnType
                },
                on: {
                  "click": _vm.onToggleActiveClick
                }
              }, [_vm._v(_vm._s(_vm.toggleBtnText))]) : _vm._e(), _c("a-button", {
                attrs: {
                  "loading": _vm.isLoading,
                  "type": "primary"
                },
                on: {
                  "click": function($event) {
                    return handleSubmit(_vm.submit);
                  }
                }
              }, [_vm._v("Save")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v;
            },
            expression: "visible"
          }
        }, [_c("a-row", {
          attrs: {
            "gutter": 24
          }
        }, [_c("a-col", {
          attrs: {
            "span": 12
          }
        }, [_c("select-input", {
          attrs: {
            "disabled": "",
            "form-item": "",
            "label": "Facility",
            "reference": "grower.common.facilities",
            "source": "code",
            "value": _vm.entity.facility
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 12
          }
        }, [_c("text-input", {
          attrs: {
            "disabled": "",
            "form-item": "",
            "label": "Crop Code",
            "value": _vm.entity.cropCode
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 8
          }
        }, [_c("text-input", {
          attrs: {
            "disabled": "",
            "form-item": "",
            "label": "Attribute Code",
            "value": _vm.entity.code
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 16
          }
        }, [_c("text-input", {
          attrs: {
            "form-item": "",
            "label": "Atttribute Short Description",
            "max-length": 15,
            "value": _vm.entity.shortDescription
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("shortDescription", $event);
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("text-input", {
          attrs: {
            "form-item": "",
            "label": "Attribute Description",
            "max-length": 40,
            "value": _vm.entity.description
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("description", $event);
            }
          }
        })], 1)], 1), _c("a-divider"), _c("a-row", {
          attrs: {
            "align": "middle",
            "gutter": 24,
            "type": "flex"
          }
        }, [_c("a-col", {
          attrs: {
            "span": 16
          }
        }, [_c("text-input", {
          attrs: {
            "form-item": "",
            "disabled": "",
            "label": "Sample type",
            "value": _vm.entity.sampleType
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 8
          }
        }, [_c("number-input", {
          attrs: {
            "form-item": "",
            "label": "Sequence",
            "value": _vm.entity.sequence,
            "rules": "required"
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("sequence", $event);
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 16
          }
        }, [_c("text-input", {
          attrs: {
            "form-item": "",
            "label": "Scale Prompt",
            "value": _vm.entity.jagPrompt ? _vm.entity.jagPrompt.trim() : null,
            "rules": "max:50"
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("jagPrompt", $event);
            }
          }
        })], 1), _c("a-col", {
          staticClass: "pt-3",
          attrs: {
            "span": 8
          }
        }, [_c("checkbox-input", {
          attrs: {
            "form-item": "",
            "label": "Manual Entry",
            "value": _vm.entity.manualEntry
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("manualEntry", $event);
            }
          }
        })], 1)], 1), _c("a-divider"), _c("a-row", {
          attrs: {
            "gutter": 24
          }
        }, [_c("a-col", {
          attrs: {
            "span": 7
          }
        }, [_c("switch-input", {
          attrs: {
            "form-item": "",
            "label": "Warning Tolerance",
            "value": _vm.warningTolerance
          },
          on: {
            "change": _vm.onWarningToleranceChange
          }
        })], 1), _c("a-col", {
          attrs: {
            "offset": 1,
            "span": 16
          }
        }, [_c("text-input", {
          attrs: {
            "form-item": "",
            "disabled": !_vm.warningTolerance,
            "label": "Tolerance Limit",
            "rules": "number|max_value:99",
            "value": _vm.warningToleranceLimit
          },
          on: {
            "change": _vm.onWarningToleranceLimitChange
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("text-input", {
          attrs: {
            "form-item": "",
            "disabled": !_vm.warningTolerance,
            "label": "Warning Text",
            "max-length": 50,
            "value": _vm.warningText
          },
          on: {
            "change": _vm.onWarningTextChange
          }
        })], 1)], 1)], 1)];
      }
    }])
  });
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "EditAttribute",
  inject: ["resourceProps", "can"],
  data() {
    const [attributeProps, sampleTypeProps] = this.resourceProps;
    sampleTypeProps.crud.clearFilters();
    sampleTypeProps.crud.setPagination({});
    return {
      attributeProps,
      isLoading: false,
      sampleTypeProps,
      warningText: "",
      warningTolerance: true,
      warningToleranceLimit: "",
      visible: true
    };
  },
  computed: {
    canDeactivate() {
      return this.entity.sampleType != "1";
    },
    entity() {
      return this.attributeProps.crud.getEntity() || {};
    },
    sampleTypes() {
      return this.sampleTypeProps.crud.getList();
    },
    toggleBtnText() {
      return this._.get(this.entity, "isInactive") ? "Activate" : "Deactivate";
    },
    toggleBtnType() {
      return this._.get(this.entity, "isInactive") ? "primary" : "danger";
    },
    hasDeletePermission() {
      return this.can(DELETE_PERMISSION);
    }
  },
  async created() {
    await this.attributeProps.crud.fetchEntity();
    this.warningTolerance = this.entity.warningTolerance;
    this.warningToleranceLimit = this.entity.warningToleranceLimit;
    this.warningText = this.entity.warningText;
    Object.entries(this.entity).forEach(([key, value]) => this.storeValue(key, value));
    this.onCropChange(this.entity.cropCode);
  },
  methods: {
    cancel() {
      this.visible = false;
    },
    genCropCodeLabel(option) {
      return `${option.code} - ${option.name}`;
    },
    genSampleTypeLabel(option) {
      return `${option.typeCode} - ${option.description}`;
    },
    storeValue(resourceKey, value) {
      this.attributeProps.crud.updateEntity("editForm", {
        resourceKey,
        value
      });
    },
    async submit() {
      this.isLoading = true;
      try {
        await this.attributeProps.crud.submitEntity("replace");
        this.visible = false;
      } finally {
        this.isLoading = false;
      }
    },
    async toggleActive() {
      const { crud, resourceName } = this.attributeProps;
      await crud.submitPartialEntity({
        resourceName,
        id: this.entity.id,
        data: [
          {
            op: "replace",
            path: "/IsInactive",
            value: !this.entity.isInactive
          }
        ]
      }).then(() => this.attributeProps.crud.fetchList());
      this.visible = false;
    },
    async onCropChange(value) {
      this.storeValue("cropId", value);
      const { apiUrl: apiUrl2, crud } = this.sampleTypeProps;
      await crud.fetchList(`${apiUrl2}/grower/common/sample-types?cropId=${value}`, void 0, false);
    },
    onToggleActiveClick() {
      let icon, okType, title;
      if (this.entity.isInactive) {
        title = "Do you want to Activate this item?";
        icon = "eye";
        okType = "primary";
      } else {
        title = "Do you want to Deactivate this item?";
        icon = "eye-invisible";
        okType = "danger";
      }
      this.$confirm({
        class: "list__modal-confirm",
        icon,
        title,
        cancelText: "No",
        okText: "Yes",
        okType,
        onOk: this.toggleActive
      });
    },
    onWarningTextChange(value) {
      this.warningText = value;
      this.storeValue("warningText", value);
    },
    onWarningToleranceChange(value) {
      this.warningTolerance = value;
      this.warningToleranceLimit = "";
      this.warningText = "";
      this.storeValue("warningTolerance", value);
      this.storeValue("warningToleranceLimit", "");
      this.storeValue("warningText", "");
    },
    onWarningToleranceLimitChange(value) {
      this.warningToleranceLimit = value;
      this.storeValue("warningToleranceLimit", value);
    },
    afterModalClose() {
      this.$router.push(this.attributeProps.redirectRoute);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditAttribute = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "edit-attribute"
  }, [_c("resource", {
    attrs: {
      "name": "grower.common.facilities",
      "api-url": _vm.apiUrl,
      "resource-id-name": "code"
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.common.crops",
      "api-url": _vm.apiUrl,
      "resource-id-name": "code"
    }
  }), _c("resource", {
    attrs: {
      "name": ["grower.attributes", "grower.common.sample-types"],
      "api-url": _vm.apiUrl,
      "redirect-route": ["/watties-grower/attributes"],
      "page": _vm.page
    }
  }, [_c("edit-attribute")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditAttribute
  },
  data() {
    return {
      apiUrl,
      page: GROWERS_ATTRIBUTES
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
